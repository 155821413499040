<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-02-15 11:13:02
 * @LastEditors: ------
 * @LastEditTime: 2022-02-17 10:28:55
-->
<template>
  <div>
    <van-nav-bar
      title="创建服务合同"
      left-text="返回"
      fixed
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <van-form @submit="onSubmit">
      <van-field name="isRenew" label="合同类型" input-align="right">
        <template #input>
          <van-radio-group
            v-model="setContractInfo.isRenew"
            direction="horizontal"
          >
            <van-radio name="N" checked-color="#FFB255">新建合同</van-radio>
            <van-radio name="Y" checked-color="#FFB255">续签合同</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="setContractInfo.name"
        name="name"
        label="用户姓名"
        placeholder="请输入用户姓名"
        input-align="right"
        @input="onInput()"
        :rules="[{ required: true }]"
      />
      <van-popup
        v-model="show"
        position="right"
        :style="{ width: '30%', height: '100%' }"
      >
        <van-button
          v-for="(item, index) in myData"
          :key="index"
          style="border: none; display: block"
          @click.prevent="myInfo(item)"
          >{{ item.text }}</van-button
        >
      </van-popup>
      <van-field
        v-model="setContractInfo.mobile"
        name="mobile"
        label="用户手机号"
        type="tel"
        placeholder="请输入用户手机号"
        input-align="right"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="setContractInfo.idCard"
        name="idCard"
        label="用户身份证"
        placeholder="请输入用户身份证号"
        input-align="right"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="setContractInfo.ServeName"
        name="ServeName"
        label="服务对象姓名"
        placeholder="请输入服务对象姓名"
        input-align="right"
        :rules="[{ required: true }]"
      />
      <van-field
        readonly
        clickable
        name="serviceCity"
        :value="position"
        label="所在城市"
        placeholder="省/市/区 >"
        input-align="right"
        @click="showArea = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
      <van-field
        v-model="address"
        name="detailAddress"
        label="居住地址"
        placeholder="请输入服务对象居住地址 >"
        input-align="right"
        :rules="[{ required: true }]"
      />
      <div style="margin: 16px" class="btn">
        <van-button
          round
          block
          color="linear-gradient(-90deg, #FF9A31, #FFD385)"
          native-type="submit"
          >下一步，选择服务类型</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import { getServeName, getNurseFind } from "@/api/index.js";
export default {
  name: "",
  data() {
    return {
      showArea: false,
      show: false,
      areaList, // 数据格式见 Area 组件文档
      address: "",
      position: "",
      serviceCity: "",
      setContractInfo: {
        ServeType: "",
        name: "",
        mobile: "",
        idCard: "",
        ServeName: "",
        serviceCity: "",
        detailAddress: "",
        accountId: "",
        flag: "0",
        accountFamilyMemberId: "",
        accountFamilyMobile: "",
        isRenew: "N",
        timeout: null,
      },
      myData: [],
    };
  },
  methods: {
    onSubmit(values) {
      values.ServeType = values.isRenew == "N" ? "1" : "2";
      values.detailAddress = this.position + this.address;
      values.serviceCity = this.serviceCity;
      values.type = this.$route.query.num;
      values.accountId = this.setContractInfo.accountId;
      values.accountFamilyMobile = this.setContractInfo.accountFamilyMobile;
      values.accountFamilyMemberId = this.setContractInfo.accountFamilyMemberId;
      this.$router.push({
        path: "/chooseServe",
        query: {
          obj: JSON.stringify(values),
        },
      });
    },
    onConfirm(values) {
      //点击跳过保存时函数
      // this.contract.county = values[2].code;
      // this.contract.city = this.contract.county.slice(0, 4);
      // console.log(values[2].code.slice(0, 4));
      this.serviceCity = values[2].code.slice(0, 4);
      // this.contract.province = this.contract.county.slice(0, 2);
      this.position = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("");
      // this.contract.mycity = this.value;

      this.showArea = false;
    },
    inputChange() {
      if (this.setContractInfo.name != "") {
        this.showPopup();
      }
    },

    debounce(func, wait) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        func();
      }, wait);
    },
    // input 实时输入
    onInput() {
      this.debounce(this.inputChange, 700);
    },
    async showPopup() {
      this.myData = [];
      let res1 = await getNurseFind({
        key: this.setContractInfo.name,
        volunteer: false,
      });
      var result = res1.data.result;
      console.log(result);
      if (res1.data.success) {
        result.forEach((r) => {
          this.myData.push({
            value: r.id,
            text: r.name,
            phone: r.mobile,
            idCard: r.idCard,
          });
        });
      }
      console.log(this.myData);
      if (result.length != 0) {
        this.show = true;
      }
    },
    async myInfo(e) {
      console.log(e.value);
      this.setContractInfo.name = e.text;
      this.setContractInfo.mobile = e.phone;
      this.setContractInfo.idCard = e.idCard;

      let res = await getServeName({ id: e.value });
      let ServeName1 = res.data.result;
      this.setContractInfo.ServeName = ServeName1[0].name;
      this.setContractInfo.accountId = ServeName1[0].accountId;
      this.setContractInfo.accountFamilyMobile = ServeName1[0].mobile;
      this.setContractInfo.accountFamilyMemberId = ServeName1[0].id;
      this.show = false;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar {
  background: #ff9a31;

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-nav-bar__left {
  .van-icon,
  .van-nav-bar__text {
    color: #fff;
  }
}
.btn {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
